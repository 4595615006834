import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getSpendActivityById,
  approveApprovalRequest,
  resetState,
} from "../actions";

import * as S from "../../style";
import { TEMPO } from "../../../../../constants/navRoutes";

import GoBack from "../../../../../components/GoBack";
import Loading from "../../../../../components/Loading";
import { Regular } from "../../../../../components/Button";
import { Row, Col } from "../../../../../components/Grid";
import * as T from "../../../../../components/Typograpy";

import { tempoRoles } from "../../../../../constants/roles";
import { LANGUAGE_ERROR } from "../../../../../validation/err-msgs";
import t from "../../../../../utils/helpers/translator";
import WarningMessage from "../../../../../components/WarningMessage";
import Modal from "../../../../../components/Modal";

// SECTIONS

import Content from "./Content";
import Icon from "../../../../../components/Icon";

const SpendActivity = (props) => {
  const {
    isTablet,
    spendActivity,
    userRole,
    userId,
    getSpendActivityByIdAction,
    spendActivityLoading,
    language,
    approveRequestLoading,
    approveRequestDone,
    approveApprovalRequestAction,
    resetStateAction,
  } = props;

  const { id } = useParams();
  const history = useHistory();
  const allowedRoles = Object.values(tempoRoles);

  const [pageState, setPageState] = useState(null);
  const [approveMessageOpen, setApproveMessageOpen] = useState(false);
  const [approvedSuccess, setApprovedSuccess] = useState(false);

  useEffect(() => {
    getSpendActivityByIdAction(id, "spend", { isPublic: true });
    return resetStateAction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    image: { imageURL } = {},
    welshFields = {},
    spendVenue = {},
    activityRestrictions = {},
    reachedRestrictions = {},
    restrictions: restrictionsText,
  } = spendActivity;

  const { spendVenue: spendVenueWelsh = {}, earnGroup = {} } = welshFields;
  // object to be rendered in content page
  const welshVersion = {
    ...welshFields,
    personTcPrice: spendActivity.personTcPrice,
    costVaries: spendActivity.costVaries,
    activityTypes: spendActivity.activityTypes,
    isBookingRequiredForGroups: spendActivity.isBookingRequiredForGroups,
    maxGroupSize: spendActivity.maxGroupSize,
    dates: spendActivity.dates,
    endDate: spendActivity.endDate,
    isAvailableAnyTime: spendActivity.isAvailableAnyTime,
    spendVenue: {
      addresses: spendVenueWelsh.addresses || spendVenue.addresses,
      name: spendVenueWelsh.name || earnGroup.name || spendVenue.name,
      addressLine1: spendVenueWelsh.addressLine1 || spendVenue.addressLine1,
      addressLine2: spendVenueWelsh.addressLine2 || spendVenue.addressLine2,
      description: spendVenueWelsh.description || spendVenue.description,
      postcode: spendVenue.postcode,
      city: spendVenueWelsh.city,
      id: spendVenue.id,
      userRole: spendVenue.userRole,
      logoImageURL: spendVenue.logoImageURL,
    },
  };

  const userIsFromVenue = spendVenue.id === userId || spendActivity.rootOwner === userId;

  const welshVersionExists = welshFields && welshFields.name;
  const flowLanguage =
    welshVersionExists && language === "welsh" ? "welsh" : "english";
  useEffect(() => {
    if (welshVersionExists && language === "welsh") {
      setPageState({ ...welshVersion, languageError: null });
    } else if (!welshVersionExists && language === "welsh") {
      setPageState({
        ...spendActivity,
        languageError: t(LANGUAGE_ERROR, language),
      });
    } else {
      setPageState({ ...spendActivity, languageError: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, spendActivity.id]);

  const handleApproveActivity = async () => {
    await approveApprovalRequestAction(spendActivity.id);
    setApprovedSuccess(true);
  };

  if (spendActivityLoading || !pageState) return <Loading fullPage navbar />;
  return (
    <>
      <S.Wrapper lessPadding>
        {isTablet && (
          <Row>
            <Col w={[2, 6, 8]}>
              <GoBack
                showText
                textColor="gray1"
                onClick={() => history.goBack()}
                lang={flowLanguage}
              />
            </Col>
          </Row>
        )}
        {allowedRoles.includes(userRole) && (
          <>
            <S.EditWrapper>
              <Regular
                bgColor="pink"
                customWidth="100px"
                to={TEMPO.EDIT_SPEND_ACTIVITY.replace(":id", id)}
                icon={
                  <Icon
                    icon="edit"
                    width="24"
                    height="24"
                    mt={3}
                    color="white"
                  />
                }
              />
              <Regular
                ml="16"
                mt="3"
                bgColor="pink"
                customWidth="100px"
                to={TEMPO.DUPLICATE_SPEND_ACTIVITY.replace(":id", id)}
                icon={
                  <Icon
                    icon="copy"
                    width="24"
                    height="24"
                    mt={3}
                    color="white"
                  />
                }
              />
              {spendActivity.isUnderReview && !approveRequestDone && (
                <Regular
                  ml="16"
                  bgColor="blue"
                  customWidth="200px"
                  onClick={() => setApproveMessageOpen(true)}
                >
                  Approve activity
                </Regular>
              )}
            </S.EditWrapper>
          </>
        )}
        <S.ImageWrapper img={imageURL} />
        {!isTablet && (
          <Row>
            <Col w={[2, 9, 8]}>
              <GoBack
                showText
                p="0 0 40px 0"
                onClick={() => history.goBack()}
                lang={flowLanguage}
              />
            </Col>
          </Row>
        )}
        {pageState.languageError && (
          <T.Body16R color="pink" style={{ marginBottom: 40 }}>
            {pageState.languageError}
          </T.Body16R>
        )}

        <Content
          isTablet={isTablet}
          spendActivity={pageState}
          userRole={userRole}
          userIsFromVenue={userIsFromVenue}
          id={id}
          lang={flowLanguage}
          welshVersion={welshVersionExists}
          restrictions={{
            capacity: activityRestrictions.capacity,
            perMonth: activityRestrictions.capacityMonth,
            perVolunteerPerMonth: activityRestrictions.capacityVolunteerMonth,
            perWeek: activityRestrictions.capacityWeek,
            perVolunteerPerWeek: activityRestrictions.capacityVolunteerWeek,
            perTransaction: activityRestrictions.capacityPeoplePerTransaction,
            perSlot: activityRestrictions.capacitySlot,
            perVolunteer: activityRestrictions.capacityVolunteer,
          }}
          restrictionsText={restrictionsText}
          reachedRestrictions={reachedRestrictions}
        />
      </S.Wrapper>
      <WarningMessage
        open={approveMessageOpen}
        setOpen={setApproveMessageOpen}
        handleClick={handleApproveActivity}
        title="Are you sure you want to approve this activity?"
        text="Once you approve, this offer will be ready to go live"
        loading={approveRequestLoading}
      />
      <Modal
        modalOpen={approvedSuccess}
        handleCloseModal={() => setApprovedSuccess(false)}
        confirmFun={() => {
          history.push(TEMPO.RECOGNITION_LISTING_REQUESTS);
        }}
        onCancel={() => setApprovedSuccess(false)}
      >
        <T.H24 color="blue" mb={20}>
          Activity approved
        </T.H24>
        <T.Body14R>
          Please remember to close the ticket in Zendesk and notify the partner.
        </T.Body14R>
      </Modal>
    </>
  );
};

// NEED TO UPDATE TO GET FROM REDUX STATE ONCE BACKEND IS DONE
const mapStateToProps = (state) => ({
  userRole: state.auth.role,
  userId: state.auth.id,
  approveRequestLoading: state.activityProfile.approveRequestLoading,
  approveRequestError: state.activityProfile.approveRequestError,
  approveRequestDone: state.activityProfile.approveRequestDone,
  spendActivity: state.activityProfile.spendActivityData,
  spendActivityLoading: state.activityProfile.getSpendActivityLoading,
  personTcPrice: state.activityProfile.spendActivityData.personTcPrice,
  activityTypes: state.activityProfile.spendActivityData.activityTypes,
  isBookingRequiredForGroups:
    state.activityProfile.spendActivityData.isBookingRequiredForGroups,
  maxGroupSize: state.activityProfile.spendActivityData.maxGroupSize,
  dates: state.activityProfile.spendActivityData.dates,
  endDate: state.activityProfile.spendActivityData.endDate,
  isAvailableAnyTime:
    state.activityProfile.spendActivityData.isAvailableAnyTime,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  // ACTIONS TO GO HERE
  getSpendActivityByIdAction: getSpendActivityById,
  approveApprovalRequestAction: approveApprovalRequest,
  resetStateAction: resetState,
};

export default connect(mapStateToProps, mapActionToProps)(SpendActivity);
