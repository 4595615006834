import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ theme, size, fullWidth }) => ({
  display: "flex",
  background: theme.colors.cyanLight,
  minWidth: "260px",
  padding: theme.spacing(2),
  margin: "0",
  maxWidth: fullWidth ? "100%" : "300px",
  [theme.breakpoints.mobileXL]: {
    maxWidth: size === "small" ? "375px" : "100%",
  },
}));

export const GraphicWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: theme.colors.cyanLight,
  minWidth: "260px",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  margin: "0",
  border: `2px solid ${theme.colors.cyanMed}`,
  borderRadius: theme.borders.radius,

  [theme.breakpoints.mobileXL]: {
    flexDirection: "row",
    paddingLeft: theme.spacing(3),
  },
}));

export const GraphicBody = styled("div")(({ theme }) => ({
  maxWidth: "80%",
  wordWrap: "break-word !important",
  width: "80%",
  overflowWrap: "break-word",
  "-ms-word-break": "break-all",
  wordBreak: "break-word",
  paddingTop: theme.spacing(3),

  [theme.breakpoints.mobileXL]: {
    paddingTop: "0",
  },
}));

export const Body = styled("div")(() => ({
  maxWidth: "80%",
  wordWrap: "break-word !important",
  width: "80%",
  overflowWrap: "break-word",
  "-ms-word-break": "break-all",
  wordBreak: "break-word",
}));

export const RestrictionsWrapper = styled("div")(({ theme, p }) => ({
  minWidth: "300px",
  maxWidth: "375px",
  display: "flex",
  padding: p || theme.spacing(3),
  [theme.breakpoints.mobileXL]: {
    maxWidth: "735px",
  },
}));

export const RestrictionsBody = styled("span")(({ theme }) => ({
  maxWidth: "80%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  [theme.breakpoints.mobileXL]: {
    "& h1, h2, h3, h4, h5, h6": {
      fontSize: "22px",
      lineHeight: "25px",
      fontWeight: "900",
    },
  },
}));

export const List = styled("ul")(() => ({
  padding: "10px 0",
  margin: "0",
}));

export const ListItem = styled("li")(({ theme }) => ({
  listStyleType: "none",
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.mobileXL]: {
    "& p": {
      fontSize: "16px",
    },
  },
}));

export const StyledHyphen = styled("span")(() => ({
  fontSize: "14px",
  fontWeight: "400",
}));

export const EmailHeader = styled("span")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const EmailWrapper = styled("div")(({ theme, modal, margin }) => ({
  background: theme.colors.pinkLight,
  boxShadow: theme.customShadows.card,
  padding: theme.spacing(3),
  minWidth: "310px",
  maxWidth: "530px",
  position: modal ? "absolute" : "relative",
  bottom: "0",
  margin,

  [theme.breakpoints.tablet]: {
    padding: theme.spacing(5),
  },
}));

export const EmailBody = styled("div")(({ theme }) => ({
  wordWrap: "break-word",
  paddingTop: theme.spacing(2),

  [theme.breakpoints.mobileXL]: {
    width: "80%",
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  paddingTop: theme.spacing(3),
  maxWidth: "300px",
  margin: "0 auto",

  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));
