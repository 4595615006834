import { styled } from "@material-ui/core/styles";
import * as T from "../../../components/Typograpy";

export const Wrapper = styled("main")(({ theme, lessPadding }) => ({
  paddingBottom: lessPadding ? "0" : theme.spacing(10),
  [theme.breakpoints.tablet]: {
    paddingTop: "340px",
    paddingBottom: "0",
  },
}));

export const ImageWrapper = styled("div")(({ theme, img }) => ({
  marginTop: theme.spacing(4),
  height: "216px",
  width: "100%",
  background: `url("${img}") no-repeat top center`,
  backgroundSize: "cover",
  clipPath: "polygon(0 6%, 100% 0, 100% 94%, 0 100%)",
  marginBottom: theme.spacing(5),

  [theme.breakpoints.tablet]: {
    position: "absolute",
    top: "-25px",
    left: "320px",
    width: "calc(100% - 320px)",
    height: "380px",
    clipPath: "none",
    zIndex: "-1",
  },
}));

export const Header = styled("header")(({ theme }) => ({
  marginBottom: theme.spacing(5),

  [theme.breakpoints.tablet]: {
    marginBottom: theme.spacing(6),
  },
}));

export const ActivityWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  paddingTop: theme.spacing(3),
}));

export const LogoWrapper = styled("div")(({ theme, logo }) => ({
  background: `no-repeat url("${logo}")`,
  backgroundPosition: `0 100%`,
  backgroundSize: "contain",
  width: "100%",
  height: "50%",
  minHeight: "140px",
  borderRadius: theme.borders.radius,
  marginTop: theme.spacing(4),
}));

export const TagWrapper = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
}));

export const LeftWrapper = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(8),

  [theme.breakpoints.tablet]: {
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  background: "none",
  padding: "none",
  width: "auto",

  [theme.gridBreakpoints.tablet]: {
    zIndex: 100,
    height: "100px",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    background: theme.colors.white,
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100vw",
    marginBottom: "0",

    [theme.breakpoints.tablet]: {
      left: "320px",
      width: "calc(100vw - 320px)",
    },
  },
}));

export const SuccessHeader = styled("header")(({ theme, p }) => ({
  paddingBottom: p || theme.spacing(2),
  textAlign: "center",
}));

export const SuccessImage = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const GetInTouchIconWrapper = styled("div")(() => ({
  minWidth: "35px",
}));

export const Link = styled("a")(({ theme }) => ({
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  wordWrap: "break-word !important",
  overflowWrap: "break-word !important",
  "-ms-word-break": "break-all !important",
  wordBreak: "break-all !important",
}));

export const ActivitiesWrapper = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(8),
}));

export const CreditWrapper = styled("div")(({ theme }) => ({
  opacity: "0",
  position: "absolute",
  [theme.gridBreakpoints.tablet]: {
    opacity: "1",
    position: "relative",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

export const EditButtonWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: "1",
  left: 350,
  display: "none",

  [theme.breakpoints.tablet]: {
    top: theme.spacing(5),
    display: "block",
  },
}));

export const LoadMoreButton = styled("button")(({ theme }) => ({
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
  marginTop: theme.spacing(1),
}));

// //////// THESE BELOW MAY NOT BE NEEDED BUT CAN DECIDE ONCE SPEND PROFILE IS DONE AS WELL

export const TopDetails = styled("div")(({ theme, type }) => ({
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.mobileL]: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    // justifyContent: "space-evenly",
  },

  [theme.breakpoints.tablet]: {
    paddingLeft: theme.spacing(8),
    paddingRight: type === "earn" ? "100px" : "375px",
  },
}));

export const SectionWrapper = styled("div")(({ theme, type }) => ({
  padding: theme.spacing(5),
  paddingTop: 0,
  display: "flex",
  flexWrap: "wrap",
  [theme.breakpoints.tablet]: {
    paddingLeft: theme.spacing(8),
    paddingRight: type === "earn" ? "100px" : "375px",
  },
}));

export const Section = styled("div")(({ theme, type }) => ({
  minWidth: "280px",
  width: "100%",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  borderBottom: `1px solid ${theme.colors.gray6}`,

  [theme.breakpoints.mobileL]: {
    marginRight: theme.spacing(8),
    width: type === "earn" ? "350px" : "300px",
    border: "none",
  },
}));

export const HowToSpend = styled("aside")(({ theme, open }) => ({
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100vw",
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  background: theme.gradients.blue,
  height: open ? "568px" : theme.spacing(9),
  transition: "all ease 0.2s",
  zIndex: "999",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.tablet]: {
    position: "absolute",
    right: "0",
    left: "auto",
    top: "380px",
    width: "auto",
    maxWidth: "340px",
    padding: theme.spacing(7),
    zIndex: "1",
    height: "calc(100% - 380px)",
  },

  [theme.breakpoints.laptop]: {
    padding: theme.spacing(7),
    maxWidth: "375px",
  },
}));

export const Heading = styled(T.H30)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  textTransform: "uppercase",
  color: "midnight",
}));

export const StyledLink = styled("a")(({ theme }) => ({
  color: theme.colors.blue,
  fontWeight: "bold",
}));

export const StyledIconBtn = styled("button")(({ open }) => ({
  background: "none",
  border: "none",
  transition: "all ease 0.2s",
  transform: open && "rotate(180deg)",
  outline: "none",
  cursor: "pointer",
}));

export const HowToHeader = styled("header")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(5),
  [theme.breakpoints.tablet]: {
    marginBottom: theme.spacing(7),
  },
}));

export const Step = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(5),

  "& *": {
    color: theme.colors.white,
  },
}));

export const StepDetails = styled("span")(({ theme }) => ({
  paddingLeft: theme.spacing(3),
}));

export const VenueDetails = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  paddingTop: theme.spacing(4),
  display: "flex",
  flexWrap: "wrap",
  marginBottom: theme.spacing(6),

  [theme.breakpoints.tablet]: {
    paddingLeft: theme.spacing(8),
    paddingRight: "375px",
  },
}));

export const Detail = styled("span")(({ theme, address }) => ({
  display: "flex",
  width: "100%",
  marginBottom: theme.spacing(2),

  [theme.breakpoints.mobileL]: {
    width: address ? "45%" : "100%",
  },

  [theme.breakpoints.tablet]: {
    width: "100%",
  },

  [theme.breakpoints.laptopL]: {
    width: address ? "45%" : "100%",
  },
}));

export const ContactLinks = styled("div")(() => ({}));

export const Address = styled("span")(() => ({}));

export const ExtLink = styled("a")(({ underline }) => ({
  textDecoration: underline ? "underline" : "none",
}));

export const Description = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  marginBottom: theme.spacing(6),

  [theme.breakpoints.tablet]: {
    paddingLeft: theme.spacing(8),
    paddingRight: "375px",
  },
}));

export const ActivityHeader = styled("header")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(5),

  [theme.breakpoints.tablet]: {
    width: "calc(100% - 285px)",
  },
}));

export const Line = styled("span")(({ theme, left }) => ({
  height: "1px",
  width: "100%",
  background: theme.colors.gray6,
  margin: left ? "0 0 0 10%" : "0 10% 0 0",
}));

export const Activity = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(5),
  borderBottom: `1px solid ${theme.colors.gray6}`,

  [theme.breakpoints.tablet]: {
    marginLeft: theme.spacing(8),
    marginRight: "335px",
  },
}));

export const ActivityDetails = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  paddingBottom: theme.spacing(4),
}));

export const ActivityTitle = styled("span")(() => ({}));

export const ReviewWrapper = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(5),

  [theme.breakpoints.tablet]: {
    paddingLeft: theme.spacing(8),
    paddingright: "335px",
  },
}));

export const StarWrapper = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingTop: theme.spacing(0),
  paddingLeft: theme.spacing(5),

  [theme.breakpoints.tablet]: {
    paddingright: "335px",
  },
}));

export const EditWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: "1",
  left: 350,
  display: "none",

  [theme.breakpoints.tablet]: {
    top: theme.spacing(4),
    display: "flex",
  },
}));

export const GetInTouchSection = styled("div")(() => ({
  margin: "0 auto 120px",
  maxWidth: "550px",
}));

export const ListItem = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(4),
  wordWrap: "break-word !important",
  overflowWrap: "break-word",
  "-ms-word-break": "break-all",
  wordBreak: "break-word",
  width: "100%",
}));
