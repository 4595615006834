import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getSpendActivityById,
  purchaseBookingCodes,
} from "../../GeneralRoutes/Profile/Activity/actions";

import { logout } from "../../../redux/actions/auth";

import userRoles from "../../../constants/roles";
import { GENERAL } from "../../../constants/navRoutes";

import Loading from "../../../components/Loading";
import Modal from "../../../components/Modal";
import * as T from "../../../components/Typograpy";

import translate from "../../../utils/helpers/translator";

import BookingCodes from "../../GeneralRoutes/Profile/Activity/SpendOptions/BookingCodes";

const SpendOnBehalf = ({
  loggedIn,
  role,
  isTablet,
  getSpendActivityByIdAction,
  spendActivityLoading,
  spendActivity,
  purchaseBookingCodesAction,
  purchaseBookingCodesLoading,
  language,
}) => {

  const history = useHistory();

  const [pageState, setPageState] = useState({
    authorized: false,
  });

  const [successModalOpen, setSuccessModelOpen] = useState(false);

  const { activityId: id } = useParams();

  useEffect(() => {
    getSpendActivityByIdAction(id, "spend", { isPublic: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn && role === userRoles.MEMBER) {
      setPageState((oldState) => ({ ...oldState, authorized: true }));
    }
  }, [loggedIn, role]);

  if (spendActivityLoading || !spendActivity?.id) return <Loading fullPage navbar />;

  const {
    personTcPrice: cost,
    useCodesDetails,
    bookingCodes = [],
    numberOfAvailableCodes,
    welshFields = {},
    reachedRestrictions,
  } = spendActivity;

  const { useCodesDetails: useCodesDetailsWelsh = "" } = welshFields;

  const welshVersion = welshFields && welshFields.useCodesDetails;

  const welshVersionExists = welshFields && welshFields.name;
  const flowLanguage =
    welshVersionExists && language === "welsh" ? "welsh" : "english";

  const goBack = () => history.push(GENERAL.SPEND_ACTIVITY.replace(":id", id))

  return (
    <>
      <BookingCodes
        forSomebodyElse={true}
        back={goBack}
        cost={cost}
        activityId={id}
        codesExtraDetails={useCodesDetails}
        bookingCodes={bookingCodes}
        numberOfAvailableCodes={numberOfAvailableCodes}
        isTablet={isTablet}
        purchaseBookingCodesAction={purchaseBookingCodesAction}
        purchaseBookingCodesLoading={purchaseBookingCodesLoading}
        onSuccess={() => setSuccessModelOpen(true)}
        welshVersion={welshVersion}
        codesExtraDetailsWelsh={useCodesDetailsWelsh}
        authorized={pageState.authorized}
        language={flowLanguage}
        reachedRestrictions={reachedRestrictions}
      />
      {successModalOpen && (
        <Modal
          modalOpen={successModalOpen}
          handleCloseModal={goBack}
          buttonText={translate("backToVenue", language)}
        >
          <T.Body20B color="blue">
            {translate("success", language)}
          </T.Body20B>
          <T.Body16B color="gray3" mt="15">
            {translate("theirAccessCodesWillBeEmailed", language)}
          </T.Body16B>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
  role: state.auth.role,
  spendActivity: state.activityProfile.spendActivityData,
  spendActivityLoading: state.activityProfile.getSpendActivityLoading,
  purchasedBookingCodes: state.activityProfile.purchasedBookingCodesData,
  purchaseBookingCodesLoading:
    state.activityProfile.purchaseBookingCodesLoading,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  getSpendActivityByIdAction: getSpendActivityById,
  purchaseBookingCodesAction: purchaseBookingCodes,
  logoutAction: logout,
};

export default connect(mapStateToProps, mapActionToProps)(SpendOnBehalf);
