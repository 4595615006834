/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import validate from "../../../../../../validation/schemas/purchaseBookingCodes";
import { LANGUAGE_ERROR } from "../../../../../../validation/err-msgs";

import * as S from "../style";

import { Row, Col } from "../../../../../../components/Grid";
import GoBack from "../../../../../../components/GoBack";
import { Body16R } from "../../../../../../components/Typograpy";
import * as actions from "../../actions";
import { SPEND_VENUE } from "../../../../../../constants/navRoutes";
import t from "../../../../../../utils/helpers/translator";
import isSoldOut from "../../../../../../utils/helpers/is-sold-out";

import Content from "./Content";

const BookingCodes = ({
  forSomebodyElse,
  loggedInMembershipId,
  back,
  cost,
  activityId,
  numberOfAvailableCodes,
  loggedInBalance,
  isTablet,
  purchaseBookingCodesAction,
  purchaseBookingCodesLoading,
  onSuccess,
  getActivityCodesUsedByUser,
  welshVersion,
  reachedRestrictions,
  language,
}) => {
  const history = useHistory();
  const [memberId, setMemberId] = useState(forSomebodyElse ? null : loggedInMembershipId);

  const [adultsParticipants, setAdultsParticipants] = useState({
    counter: 0,
    error: null,
  });
  const [childrenParticipants, setChildrenParticipants] = useState({
    counter: 0,
    error: null,
  });

  const [errors, setError] = useState({});
  const [price, setPrice] = useState(null);

  const isDisabled = !price || !memberId;

  const onChangeValidate = () => {
    const wantedCodes =
      Number(adultsParticipants.counter) + Number(childrenParticipants.counter);

    const soldOut = isSoldOut(
      reachedRestrictions,
      Number(adultsParticipants.counter || 0),
      Number(childrenParticipants.counter || 0),
    );

    if (soldOut) {
      return setError({
        preSubmitError: {
          msg: soldOut,
        },
      });
    }

    if (wantedCodes > numberOfAvailableCodes)
      return setError({
        preSubmitError: {
          msg: "notEnoughCodes",
          customData: [numberOfAvailableCodes],
        },
      });
    if (!forSomebodyElse && price > loggedInBalance)
      return setError({
        preSubmitError: { msg: "notEnoughTCs" },
      });

    setError({});
  };

  const handleCounter = (value, type) => {
    if (type === "children") {
      setChildrenParticipants(value);
      setPrice(
        (Number(value.counter) + Number(adultsParticipants.counter)) * cost,
      );
    }
    if (type === "adults") {
      setAdultsParticipants(value);
      setPrice(
        (Number(value.counter) + Number(childrenParticipants.counter)) * cost,
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getActivityCodesUsedByUser({ activityId });
  }, [activityId]);

  useEffect(() => {
    onChangeValidate();
  }, [price]);

  const onSubmit = async () => {
    try {
      const purchaseDetails = {
        membershipId: memberId,
        childrenParticipants: childrenParticipants.counter,
        adultsParticipants: adultsParticipants.counter,
        activityId,
      };

      await validate({
        ...purchaseDetails,
      });

      const { data, error } = await purchaseBookingCodesAction({
        activityId,
        purchaseDetails,
      });
      if (data) {
        onSuccess();
        setError({});
      } else if (error) {
        setError({
          preSubmitError: {
            msg: error?.message,
          },
        });
      }
    } catch (_error) {
      if (_error.name === "ValidationError") {
        setError({ ..._error.inner });
      } else {
        history.push(SPEND_VENUE.ACCEPT_TC_FAIL, { membershipId: memberId });
      }
    }
  };

  useEffect(() => {
    if (welshVersion && language === "welsh") {
      setError((oldErrors) => ({ ...oldErrors, languageError: null }));
    } else if (!welshVersion && language === "welsh") {
      setError((oldErrors) => ({
        ...oldErrors,
        languageError: t(LANGUAGE_ERROR, language),
      }));
    } else {
      setError((oldErrors) => ({ ...oldErrors, languageError: null }));
    }
  }, [language]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <S.OptionWrapper>
      <Row mb={isMobile ? 3 : 6}>
        <Col w={[2, 6, 8]}>
          <GoBack
            showText
            onClick={back}
            lang={welshVersion && language === "welsh" && "welsh"}
          />
        </Col>
      </Row>
      {errors.languageError && (
        <Body16R color="pink" style={{ marginBottom: 40 }}>
          {errors.languageError}
        </Body16R>
      )}
      <Content
        isTablet={isTablet}
        errors={errors}
        childrenParticipants={childrenParticipants}
        handleCounter={handleCounter}
        price={price}
        onSubmit={onSubmit}
        isDisabled={isDisabled}
        purchaseBookingCodesLoading={purchaseBookingCodesLoading}
        adultsParticipants={adultsParticipants}
        lang={welshVersion ? language : "english"}
        changeMember={forSomebodyElse ? setMemberId : null}
      />
    </S.OptionWrapper>
  );
};

const mapStateToProps = (state) => ({
  loggedInMembershipId: state.auth.profile.membershipId,
  loggedInBalance: state.user.tc.tcBalance,
});

const mapActionToProps = {
  getActivityCodesUsedByUser: actions.getActivityCodesUsedByUser,
};
export default connect(mapStateToProps, mapActionToProps)(BookingCodes);
