import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import * as S from "../../style";
import * as T from "../../../../../components/Typograpy";
import { Row, Col } from "../../../../../components/Grid";
import { Tag } from "../../../../../components/Tags";
import TimeCredit from "../../../../../components/TimeCredit";
import { TextSection } from "../../../../../components/TextSections";
import { Regular } from "../../../../../components/Button";
import WarningMessage from "../../../../../components/WarningMessage";

import stringCutter from "../../../../../utils/helpers/stringCutter";
import t from "../../../../../utils/helpers/translator";

import { GENERAL, SPEND_VENUE } from "../../../../../constants/navRoutes";
import userRoles from "../../../../../constants/roles";
import { spendActivitiesMapWelsh } from "../../../../../constants/data/spendActivities";
import Icon from "../../../../../components/Icon";

// SECTIONS
import ActivityDates from "./ActivityDates";
import {
  getRestrictionText,
  findFirstRestrictionReached,
  hasRestrictions,
} from "./helpers";
import ShareToSocialMedia from "../../../../../components/TextSections/ShareToSocialMedia";

const Content = ({
  isTablet,
  spendActivity,
  id,
  lang,
  restrictions,
  reachedRestrictions,
  restrictionsText,
  userRole,
  userIsFromVenue,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const {
    name,
    subTitle,
    personTcPrice: cost,
    costVaries,
    activityTypes,
    isBookingRequiredForGroups,
    maxGroupSize,
    furtherInformation,
    spendVenue = {},
    dates = [],
    endDate: finalEndDate,
    isAvailableAnyTime,
    // restrictions, should be object!!
    description: activityDescription,
    externalLink,
    callToBook,
    turnUpAtVenue,
    onlineCodesToBook,
    numberOfAvailableCodes,
  } = spendActivity;

  const {
    addresses,
    addressLine1,
    addressLine2,
    postcode,
    description,
    isOnline,
    name: venueName,
    city,
  } = spendVenue;

  let findOutMoreLink = GENERAL.SPEND_VENUE.replace(":id", spendVenue.id);
  if (userRole === userRoles.EARN_GROUP) {
    findOutMoreLink = GENERAL.EARN_GROUP.replace(":id", spendVenue.id);
  }
  const firstRestrictionReached =
    findFirstRestrictionReached(reachedRestrictions);
  const noUseOptions =
    !Number(numberOfAvailableCodes) > 0 && !callToBook && !turnUpAtVenue;

  const modalText = noUseOptions
    ? t("capacityReached", lang)
    : t(`${firstRestrictionReached}Reached`, lang);
  return (
    <>
      {(firstRestrictionReached || noUseOptions) && (
        <WarningMessage
          open={open}
          setOpen={setOpen}
          handleClick={() => {}}
          title={t("currentlyUnavailable", lang)}
          text={modalText}
          withoutBack
          buttonText={t("ok", lang)}
        />
      )}
      <Row>
        <Col w={[4, 12, 8]}>
          <S.Header>
            {isTablet ? (
              <>
                <T.H30Caps color="midnight">{name}</T.H30Caps>
                {subTitle && (
                  <T.H30Caps color="midnight">- {subTitle}</T.H30Caps>
                )}
              </>
            ) : (
              <>
                <T.H40Caps color="midnight">{name}</T.H40Caps>
                {subTitle && (
                  <T.H40Caps color="midnight">- {subTitle}</T.H40Caps>
                )}
              </>
            )}
            <S.ActivityWrapper>
              {activityTypes &&
                activityTypes.map((type) => (
                  <Tag
                    tagName={
                      lang === "english" ? type : spendActivitiesMapWelsh[type]
                    }
                    m="0 10px 10px 0"
                    justify="flex-start"
                    large
                  />
                ))}
            </S.ActivityWrapper>
            <S.CreditWrapper>
              <TimeCredit
                costVaries={costVaries}
                credits={cost}
                gradient
                color="blue"
                description
                flowLanguage={lang}
              />
            </S.CreditWrapper>
          </S.Header>
          {activityDescription && (
            <Row mb={6}>
              <Col w={[4, 10, 10]} style={{ padding: 0 }}>
                <T.Body16R color="gray3">{activityDescription}</T.Body16R>
              </Col>
            </Row>
          )}
          <ActivityDates
            dates={dates}
            firstRestrictionReached={
              firstRestrictionReached &&
              t(`${firstRestrictionReached}Reached`, lang)
            }
            restrictionsText={restrictionsText}
            finalEndDate={finalEndDate}
            activityName={name}
            venueName={spendVenue.name}
            isAvailableAnyTime={isAvailableAnyTime}
            lang={lang}
          />
          <TextSection
            title={`${t("needToKnow", lang)}?`}
            text={furtherInformation}
            groupAdvance={isBookingRequiredForGroups}
            groupSize={maxGroupSize}
            size="lg"
            m="0 0 60px 0"
            lang={lang}
          />
          {hasRestrictions(restrictions) && (
            <TextSection
              title={t("restriction", lang)}
              text={getRestrictionText(restrictions, lang)}
              size="lg"
              m="0 0 60px 0"
              lang={lang}
            />
          )}
        </Col>
        <Col w={[4, 12, 4]}>
          <Col w={[0, 0, 12]}>
            <TimeCredit
              size="xl"
              credits={cost}
              costVaries={costVaries}
              gradient
              color="blue"
              description
              flowLanguage={lang}
            />
          </Col>
          <S.ButtonWrapper>
            <Regular
              primary
              bgColor="pink"
              mt="40"
              mb="40"
              onClick={() => {
                if (firstRestrictionReached || noUseOptions) {
                  return setOpen(true);
                }
                return history.push(GENERAL.SPEND_OPTIONS.replace(":id", id), {
                  spendActivity,
                  lang,
                });
              }}
              style={{ height: "60px" }}
            >
              {t("spendTC", lang)}{" "}
            </Regular>
          </S.ButtonWrapper>
          { onlineCodesToBook && ([userRoles.TEMPO_STAFF_ADMIN, userRoles.TEMPO_STAFF_SUPER ].includes(userRole) || userIsFromVenue)  && 
            <S.ButtonWrapper>
            <Regular
              primary
              bgColor="pink"
              mt="10"
              mb="40"
              disabled={numberOfAvailableCodes < 1}
              onClick={() => {
                if (firstRestrictionReached || noUseOptions) {
                  return setOpen(true);
                }
                return history.push(SPEND_VENUE.SPEND_ON_BEHALF.replace(":spendVenueId", spendVenue.id).replace(":activityId", id), {
                  spendActivity,
                  lang,
                });
              }}
              style={{ height: "60px" }}
            >
              {t("bookOnBehalf", lang)}{" "}
            </Regular>
          </S.ButtonWrapper>}
          <T.H22 color="midnight" style={{ lineHeight: "30px" }}>
            {venueName}
          </T.H22>
          {isOnline ? (
            <T.Body14R color="gray3">{t("online", lang)}</T.Body14R>
          ) : (
            <>
              {addresses && addresses.length === 1 && (
                <T.Body14R color="gray3">
                  {addresses[0].addressLine1 &&
                    `${addresses[0].addressLine1}, `}
                  {addresses[0].postcode}
                </T.Body14R>
              )}
              {addresses && addresses.length > 1 && (
                <T.Body14R color="gray3">
                  {t("multipleLocations", lang)}
                </T.Body14R>
              )}
              {(addressLine1 || postcode || city || addressLine2) && (
                <T.Body14R color="gray3">
                  {`${addressLine1 && `${addressLine1}`}${
                    (addressLine2 && `, ${addressLine2}`) || ""
                  }${city && `, ${city}`}${postcode && `, ${postcode}`}`}
                </T.Body14R>
              )}
            </>
          )}
          {description && (
            <T.Body14R color="gray3" mt="20" mb="20">
              {stringCutter(description, 230)}
            </T.Body14R>
          )}

          <Link to={findOutMoreLink}>
            <T.LinkB14 color="blue" mb="20">
              {t("findOutMore", lang)}
            </T.LinkB14>
          </Link>
          {spendVenue.logoImageURL && (
            <S.LogoWrapper logo={spendVenue.logoImageURL} />
          )}

          {externalLink && (
            <S.ListItem>
              <div>
                <Icon icon="computer" color="cyan" width="25px" height="25px" />
              </div>
              <S.Link
                href={
                  externalLink.startsWith("http")
                    ? externalLink
                    : `http://${externalLink}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <T.Body16R color="gray3" ml={15}>
                  {externalLink}
                </T.Body16R>
              </S.Link>
            </S.ListItem>
          )}
          <ShareToSocialMedia />
        </Col>
      </Row>
    </>
  );
};

export default Content;
