import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import validate, {
  validateCustomActivity,
} from "../../../validation/schemas/accept_tc";
import * as actions from "./actions";
import Content from "./Content";
import AcceptTcSuccess from "./AcceptTcSuccess";

import AcceptTcFail from "./AcceptTcFail";
import * as links from "../../../constants/navRoutes";
import Info from "../../../components/Message/Info";
import t from "../../../utils/helpers/translator";

const AcceptTimeCredits = (props) => {
  const history = useHistory();
  const mounted = useRef(false);
  const [membershipId, setMembershipId] = useState(null);
  const [customActivityName, setCustomActivityName] = useState("");
  const [customPersonTcPrice, setCustomPersonTcPrice] = useState(null);
  const [costVaryPersonTcPrice, setCostVaryPersonTcPrice] = useState(null);
  const [errors, setError] = useState({});
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [fullName, setFullName] = useState("");

  const volunteerCapacityError =
    Number(props.postError?.httpStatusCode) === 400 &&
    props?.postError?.message === "shouldProvideAuthorizationName";

  const [adultsParticipants, setAdultsParticipants] = useState({
    counter: 0,
    error: null,
  });
  const [childrenParticipants, setChildrenParticipants] = useState({
    counter: 0,
    error: null,
  });
  const [price, setPrice] = useState(null);
  const selectedActivity =
    props.activities.find((a) => a.id === selectedActivityId) || {};
  useEffect(() => {
    props.getActivities({ language: props.language, membershipId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.language]);

  useEffect(() => {
    if (mounted.current) {
      if (membershipId?.length === 8) {
        props.getActivities({ language: props.language, membershipId });
      }
    } else {
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipId]);
  /* 
  to remove the selection from the activity
  after disabling the button when selecting custom activity
  Note: this will trigger setSelected.. only once
  */
  useEffect(() => {
    if (selectedActivityId) {
      setCustomPersonTcPrice("");
      setCustomActivityName("");
    }
  }, [selectedActivityId]);
  useEffect(() => {
    if (customActivityName || customPersonTcPrice) {
      setSelectedActivityId(null);
    }
  }, [customActivityName, customPersonTcPrice]);
  useEffect(() => {
    if (
      !Number(customPersonTcPrice) &&
      !Number(selectedActivity.personTcPrice)
    ) {
      setChildrenParticipants("");
      setAdultsParticipants("");
    }
  }, [customPersonTcPrice, selectedActivity]);
  // this needs to be adjusted to sync with the UI
  const handleCounter = (value, type) => {
    if (customPersonTcPrice || selectedActivity) {
      if (type === "children") {
        setChildrenParticipants(value);
      }
      if (type === "adults") {
        setAdultsParticipants(value);
      }
    }
  };
  useEffect(() => {
    const personTcPrice =
      customPersonTcPrice ||
      costVaryPersonTcPrice ||
      selectedActivity.personTcPrice;
    setPrice(
      (Number(adultsParticipants.counter || 0) +
        Number(childrenParticipants.counter || 0)) *
        Number(personTcPrice || 0),
    );
  }, [
    childrenParticipants,
    adultsParticipants,
    customPersonTcPrice,
    costVaryPersonTcPrice,
    selectedActivity,
  ]);
  const isDisabled =
    !price ||
    !membershipId ||
    (!selectedActivityId && (!customActivityName || !customPersonTcPrice));
  const handleID = (value) => {
    const cleanID = value.toLowerCase().trim();
    setMembershipId(cleanID);
  };
  const onSubmit = async () => {
    try {
      const basicObjInfo = {
        price,
        membershipId,
        childrenParticipants: childrenParticipants.counter,
        adultsParticipants: adultsParticipants.counter,
        fullName,
      };

      if (customPersonTcPrice || customActivityName) {
        await validateCustomActivity({
          ...basicObjInfo,
          customActivityName,
          customPersonTcPrice,
        });
      } else {
        await validate({
          ...basicObjInfo,
          activityId: selectedActivityId,
        });
      }

      const getActivityIdOrCustomActivity = () => {
        if (selectedActivityId) {
          return { activityId: selectedActivityId };
        }
        return {
          customActivityName,
          customPersonTcPrice,
          isCreatedOnFly: true,
        };
      };
      // should we pass the price with the body??
      await props.acceptTimeCredits({
        ...getActivityIdOrCustomActivity(),
        membershipId,
        costVaryPersonTcPrice,
        childrenParticipants: childrenParticipants.counter,
        adultsParticipants: adultsParticipants.counter,
        confirmedByName: fullName,
        isConfirmedOnVolunteerRestriction: volunteerCapacityError,
        history,
      });
      setError({});
    } catch (error) {
      if (error.name === "ValidationError") {
        setError({
          ...error.inner,
          anyError: true,
        });
      }
    }
  };
  let _name = props.name;
  if (props.language === "welsh" && props.welshName) {
    _name = props.welshName;
  }

  useEffect(() => {
    if (!props.postError || !props.postError.message || volunteerCapacityError)
      return;

    history.push(links.SPEND_VENUE.ACCEPT_TC_FAIL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.postError.message, props.postError.httpStatusCode]);

  useEffect(() => {
    props.resetPostError();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content
      name={_name}
      membershipId={membershipId}
      setMembershipId={setMembershipId}
      activities={props.activities}
      selectedActivityId={selectedActivityId}
      selectedActivity={selectedActivity}
      adultsParticipants={adultsParticipants}
      childrenParticipants={childrenParticipants}
      setSelectedActivityId={setSelectedActivityId}
      price={price}
      handleCounter={handleCounter}
      onSubmit={onSubmit}
      postLoading={props.postLoading}
      isDisabled={isDisabled}
      errors={errors}
      handleID={handleID}
      setCustomActivityName={setCustomActivityName}
      setCustomPersonTcPrice={setCustomPersonTcPrice}
      setCostVaryPersonTcPrice={setCostVaryPersonTcPrice}
      customActivityName={customActivityName}
      customPersonTcPrice={customPersonTcPrice}
      costVaryPersonTcPrice={costVaryPersonTcPrice}
      isVerified={props.isVerified}
      language={props.language}
      role={props.role}
      fullName={fullName}
      setFullName={setFullName}
      volunteerCapacityError={volunteerCapacityError}
      setError={setError}
    />
  );
};
const mapStateToProps = (state) => ({
  getLoading: state.AcceptTC.getLoading,
  postLoading: state.AcceptTC.postLoading,
  getError: state.AcceptTC.getError,
  postError: state.AcceptTC.postError,
  activities: state.AcceptTC.activities,
  userId: state.auth.id,
  isVerified: state.auth.isVerified,
  name: state.auth.profile.name,
  welshName: state.auth.profile.welshName,
  venueIsPublic: state.auth.selectedSpendVenue.profile.isPublic,
  language: state.auth.language,
  role: state.auth.role,
});
const mapActionToProps = {
  getActivities: actions.getActivities,
  acceptTimeCredits: actions.acceptTimeCredits,
  resetPostError: actions.resetPostError,
};
export { AcceptTcSuccess, AcceptTcFail };
export default connect(mapStateToProps, mapActionToProps)(AcceptTimeCredits);
